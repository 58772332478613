/**
 * @generated SignedSource<<73ff151844d24f4bd7000ddf3f1c2fff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportTable_ReportingPlatformLib_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_company" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company" | "useVisibleMetrics_ReportingPlatform_company">;
  readonly " $fragmentType": "ReportTable_ReportingPlatformLib_company";
};
export type ReportTable_ReportingPlatformLib_company$key = {
  readonly " $data"?: ReportTable_ReportingPlatformLib_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportTable_ReportingPlatformLib_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportTable_ReportingPlatformLib_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "cc95995d410a820b095ffcf000ea4205";

export default node;
