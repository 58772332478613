/**
 * @generated SignedSource<<3bd6fe7d7cc98b14d63756eeb4e62c59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useReportDomains_AnalyticsUI_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useAiProReportingStates_ReportingPlatform_query" | "useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query">;
  readonly " $fragmentType": "useReportDomains_AnalyticsUI_query";
};
export type useReportDomains_AnalyticsUI_query$key = {
  readonly " $data"?: useReportDomains_AnalyticsUI_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useReportDomains_AnalyticsUI_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useReportDomains_AnalyticsUI_query",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAiProReportingStates_ReportingPlatform_query"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "964401400703318baf7d5545c493dabf";

export default node;
