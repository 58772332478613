/**
 * @generated SignedSource<<cfe62b15bf4a73b24520ba2f075adbda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_report$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_report">;
  readonly " $fragmentType": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report";
};
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_report$key = {
  readonly " $data"?: useGenerateReportMetricsColumnHeaders_ReportingPlatform_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateReportMetricsColumnHeaders_ReportingPlatform_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_report"
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "395cd34bbdb60e408af1f32f652e53db";

export default node;
