/**
 * @generated SignedSource<<1665497c12cb154f99f711a294e2f2a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useVisibleMetrics_ReportingPlatform_metricsTable$data = {
  readonly definitions: ReadonlyArray<{
    readonly dataType: MetricDataType;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metricsTable">;
  readonly " $fragmentType": "useVisibleMetrics_ReportingPlatform_metricsTable";
};
export type useVisibleMetrics_ReportingPlatform_metricsTable$key = {
  readonly " $data"?: useVisibleMetrics_ReportingPlatform_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_metricsTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useVisibleMetrics_ReportingPlatform_metricsTable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReportMetricsData_AnalyticsUI_metricsTable"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetricDefinition",
      "kind": "LinkedField",
      "name": "definitions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};

(node as any).hash = "657f1843a3a08dcf87b1b23003c09b41";

export default node;
