import _useSegmentReportUrlDeepLink_segment from "./__generated__/useSegmentReportUrlDeepLink_segment.graphql";
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { generateReportUrl } from '../utils';
import SegmentFragment from './__generated__/useSegmentReportUrlDeepLink_segment.graphql';
_useSegmentReportUrlDeepLink_segment;
export const useSegmentReportUrlDeepLink = _ref => {
  let {
    segmentRef,
    messageChannel,
    startDate,
    endDate
  } = _ref;
  const segment = useFragment(SegmentFragment, segmentRef);
  const {
    name: segmentName
  } = segment;
  const {
    externalId: externalCompanyId
  } = segment.company || {};

  if (!externalCompanyId) {
    throw new Error('External company ID is required to generate report URL');
  }

  const filterOperatorIn = 'FILTER_OPERATOR_IN';
  const reportParams = [{
    dimensionId: 'segment_name_aggregate_group',
    operator: filterOperatorIn,
    dimensionSets: [{
      k: segmentName,
      v: segmentName
    }]
  }, ...(messageChannel ? [{
    dimensionId: 'message_channel',
    operator: filterOperatorIn,
    dimensionSets: [{
      k: messageChannel,
      v: messageChannel
    }]
  }] : [])];
  const dateRange = startDate && endDate ? {
    startDate,
    endDate
  } : undefined;
  return {
    generateUrlForReport: reportName => generateReportUrl({
      reportName,
      reportParams,
      externalCompanyId,
      dateRange
    })
  };
};