/**
 * @generated SignedSource<<a37053f4ce2e3f505efd0c59e0c2dffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metricsTable" | "useVisibleMetrics_ReportingPlatform_metricsTable">;
  readonly " $fragmentType": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable";
};
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable$key = {
  readonly " $data"?: useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_metricsTable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReportMetricsData_AnalyticsUI_metricsTable"
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};

(node as any).hash = "7b87fd59a0db99d701bac70fe7ddaf78";

export default node;
