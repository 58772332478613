/**
 * @generated SignedSource<<cee8e141903616fede8f3d477b444a88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportTable_ReportingPlatformLib_report$data = {
  readonly reportClass: {
    readonly name: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_report" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report" | "useVisibleMetrics_ReportingPlatform_report">;
  readonly " $fragmentType": "ReportTable_ReportingPlatformLib_report";
};
export type ReportTable_ReportingPlatformLib_report$key = {
  readonly " $data"?: ReportTable_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportTable_ReportingPlatformLib_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportTable_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportClass",
      "kind": "LinkedField",
      "name": "reportClass",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_report"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_report"
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "52a3f79690abf187d7311624f5f21990";

export default node;
