/**
 * @generated SignedSource<<06e25ef584a226c7e1a6cf970e448ecb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useShouldHideCurrencyMetrics_ReportingPlatformLib_report$data = {
  readonly reportClass: {
    readonly filterDimensions: ReadonlyArray<{
      readonly dimension: {
        readonly dimensionId?: string;
        readonly granularities?: ReadonlyArray<TimeDimensionGranularity>;
        readonly name?: string;
      };
    }>;
    readonly metricDefinitions: ReadonlyArray<{
      readonly dataType: MetricDataType;
    }>;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "useShouldHideCurrencyMetrics_ReportingPlatformLib_report";
};
export type useShouldHideCurrencyMetrics_ReportingPlatformLib_report$key = {
  readonly " $data"?: useShouldHideCurrencyMetrics_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldHideCurrencyMetrics_ReportingPlatformLib_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShouldHideCurrencyMetrics_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportClass",
      "kind": "LinkedField",
      "name": "reportClass",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MetricDefinition",
          "kind": "LinkedField",
          "name": "metricDefinitions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dataType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DimensionWrapper",
          "kind": "LinkedField",
          "name": "filterDimensions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "granularities",
                      "storageKey": null
                    }
                  ],
                  "type": "TimeDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/)
                  ],
                  "type": "StringDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "8c319a4ca95696de3ce9b70845e8e9c1";

export default node;
