import _useUpdateReport_ReportingPlatformLib_Mutation from "./__generated__/useUpdateReport_ReportingPlatformLib_Mutation.graphql";
import { useMutation } from 'react-relay';
import { graphql } from '@attentive/data';
import Mutation from './__generated__/useUpdateReport_ReportingPlatformLib_Mutation.graphql';
export { Mutation as UpdateReportMutation };
_useUpdateReport_ReportingPlatformLib_Mutation;
export const useUpdateReport = () => {
  const [commit, isInFlight] = useMutation(Mutation);
  return [async (variables, onCompleted, onError) => {
    commit({
      variables,
      onCompleted: res => onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(res),
      onError: err => onError === null || onError === void 0 ? void 0 : onError(err)
    });
  }, isInFlight];
};