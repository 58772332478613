import _useToolbarRegionFilter_ReportingPlatformLib_report from "./__generated__/useToolbarRegionFilter_ReportingPlatformLib_report.graphql";
import _useToolbarRegionFilter_ReportingPlatformLib_company from "./__generated__/useToolbarRegionFilter_ReportingPlatformLib_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { useMultiRegionMode } from './useMultiRegionMode';
import CompanyFragment from './__generated__/useToolbarRegionFilter_ReportingPlatformLib_company.graphql';
import ReportFragment from './__generated__/useToolbarRegionFilter_ReportingPlatformLib_report.graphql';
_useToolbarRegionFilter_ReportingPlatformLib_company;
_useToolbarRegionFilter_ReportingPlatformLib_report;
export const useToolbarRegionFilter = _ref => {
  let {
    companyRef = null,
    reportRef = null
  } = _ref;
  const isReportingToolbarRegionFilterEnabled = useCompanyFeatureFlag('ENABLE_REPORTING_TOOLBAR_REGION_FILTER');
  const reportData = useFragment(ReportFragment, reportRef);
  const companyData = useFragment(CompanyFragment, companyRef);
  const {
    enabled: isMultiRegionEnabled
  } = useMultiRegionMode({
    companyRef: companyData || null
  });

  if (!isReportingToolbarRegionFilterEnabled || !isMultiRegionEnabled || !(reportData !== null && reportData !== void 0 && reportData.reportClass)) {
    return false;
  }

  const canFilterByRegion = reportData.reportClass.filterDimensions.some(_ref2 => {
    let {
      dimension: {
        dimensionId
      }
    } = _ref2;
    return dimensionId === 'region';
  });
  return canFilterByRegion;
};