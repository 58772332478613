/**
 * @generated SignedSource<<ad630543d2d9f302cf66fb4535b6084f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useToolbarRegionFilter_ReportingPlatformLib_report$data = {
  readonly reportClass: {
    readonly filterDimensions: ReadonlyArray<{
      readonly dimension: {
        readonly dimensionId?: string;
      };
    }>;
    readonly metricDefinitions: ReadonlyArray<{
      readonly dataType: MetricDataType;
    }>;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "useToolbarRegionFilter_ReportingPlatformLib_report";
};
export type useToolbarRegionFilter_ReportingPlatformLib_report$key = {
  readonly " $data"?: useToolbarRegionFilter_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useToolbarRegionFilter_ReportingPlatformLib_report">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dimensionId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useToolbarRegionFilter_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportClass",
      "kind": "LinkedField",
      "name": "reportClass",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MetricDefinition",
          "kind": "LinkedField",
          "name": "metricDefinitions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dataType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DimensionWrapper",
          "kind": "LinkedField",
          "name": "filterDimensions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "dimension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "TimeDimension",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "StringDimension",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "8783261e85bdf89d5978891ff6f4f8c6";

export default node;
