/**
 * @generated SignedSource<<15bfef92745816e0c903f7b72e48308d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ReportChart = "REPORT_CHART_EXPANSION_REVENUE" | "REPORT_CHART_INCREMENTAL_REVENUE" | "REPORT_CHART_LINE_CHART" | "REPORT_CHART_SUPPRESSED_SENDS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCreateReport_ReportingPlatformLib_report$data = {
  readonly selectedChart: ReportChart | null;
  readonly " $fragmentType": "useCreateReport_ReportingPlatformLib_report";
};
export type useCreateReport_ReportingPlatformLib_report$key = {
  readonly " $data"?: useCreateReport_ReportingPlatformLib_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCreateReport_ReportingPlatformLib_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCreateReport_ReportingPlatformLib_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedChart",
      "storageKey": null
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "5b4c3f596a19698ca6985efd1b4ebfc3";

export default node;
