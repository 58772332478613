/**
 * @generated SignedSource<<fdb6dd2389e709c73b52d0d04ceea3a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateRawMetricsRows_ReportingPlatform_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_company">;
  readonly " $fragmentType": "useGenerateRawMetricsRows_ReportingPlatform_company";
};
export type useGenerateRawMetricsRows_ReportingPlatform_company$key = {
  readonly " $data"?: useGenerateRawMetricsRows_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateRawMetricsRows_ReportingPlatform_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "ef94ba23217808d2520810353759eb9c";

export default node;
