/**
 * @generated SignedSource<<968b4a756a61397d708d523ac0910ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_company">;
  readonly " $fragmentType": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company";
};
export type useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$key = {
  readonly " $data"?: useGenerateReportMetricsColumnHeaders_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateReportMetricsColumnHeaders_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "cc168641f6a0c7b5fc6d9009f9cb1eed";

export default node;
