/**
 * @generated SignedSource<<4f6f9e28bedfd43ab9f151c386c72e5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_company$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_company" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_company";
};
export type ReportExportButton_ReportingPlatform_company$key = {
  readonly " $data"?: ReportExportButton_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportExportButton_ReportingPlatform_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "4b76482719fdc5c6ff637c4f5eeaa784";

export default node;
