/**
 * @generated SignedSource<<8eb74bb648ee978a1ccc79d95779e171>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportDomainsContextReportingPlatformQuery$variables = {
  companyId: string;
  first: number;
};
export type ReportDomainsContextReportingPlatformQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReportDomainsContextReportingPlatform_query">;
};
export type ReportDomainsContextReportingPlatformQuery$rawResponse = {
  readonly aiProChurnState: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly reports: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly reportClass: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly reportDomain: {
            readonly description: string;
            readonly displayOrder: number;
            readonly domainId: string;
            readonly name: string;
          };
          readonly reportId: string;
        };
      }>;
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly featureFlags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
};
export type ReportDomainsContextReportingPlatformQuery = {
  rawResponse: ReportDomainsContextReportingPlatformQuery$rawResponse;
  response: ReportDomainsContextReportingPlatformQuery$data;
  variables: ReportDomainsContextReportingPlatformQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeatureFlagEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlag",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportDomainsContextReportingPlatformQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ReportDomainsContextReportingPlatform_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportDomainsContextReportingPlatformQuery",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "first"
                  }
                ],
                "concreteType": "ReportConnection",
                "kind": "LinkedField",
                "name": "reports",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Report",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reportId",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportClass",
                            "kind": "LinkedField",
                            "name": "reportClass",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReportDomain",
                            "kind": "LinkedField",
                            "name": "reportDomain",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayOrder",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domainId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "aiProChurnState",
        "args": [
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "flagNames",
            "value": [
              "ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE"
            ]
          }
        ],
        "concreteType": "FeatureFlagConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "flagNames",
            "value": [
              "ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS",
              "ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS"
            ]
          }
        ],
        "concreteType": "FeatureFlagConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c58602409307143a4bafa4e04f003fa6",
    "id": null,
    "metadata": {},
    "name": "ReportDomainsContextReportingPlatformQuery",
    "operationKind": "query",
    "text": "query ReportDomainsContextReportingPlatformQuery(\n  $companyId: ID!\n  $first: Int!\n) {\n  ...ReportDomainsContextReportingPlatform_query\n}\n\nfragment ReportDomainsContextReportingPlatform_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    id\n    ... on Company {\n      ...useReportDomains_AnalyticsUI_company\n    }\n  }\n  ...useReportDomains_AnalyticsUI_query\n}\n\nfragment useAiProReportingStates_ReportingPlatform_query_MWiJC on Query {\n  aiProChurnState: featureFlags(companyId: $companyId, flagNames: [\"ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE\"]) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment useHasAIJourneysAccessFragment_query_MWiJC on Query {\n  featureFlags(companyId: $companyId, flagNames: [\"ENABLE_SUBSCRIPTION_OPT_IN_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PRODUCT_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_PAGE_VIEW_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_EVENT_SPLIT_FOR_AUTO_JOURNEYS\", \"ENABLE_ATC_EVENT_SPLIT_FOR_AUTO_JOURNEYS\"]) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query_MWiJC on Query {\n  ...useHasAIJourneysAccessFragment_query_MWiJC\n}\n\nfragment useReportDomains_AnalyticsUI_company on Company {\n  reports(first: $first) {\n    edges {\n      node {\n        id\n        reportId\n        name\n        reportClass {\n          name\n          id\n        }\n        reportDomain {\n          name\n          description\n          displayOrder\n          domainId\n        }\n      }\n    }\n  }\n}\n\nfragment useReportDomains_AnalyticsUI_query on Query {\n  ...useAiProReportingStates_ReportingPlatform_query_MWiJC\n  ...useHasAIJourneysPerformanceReportAccess_ReportingPlatform_query_MWiJC\n}\n"
  }
};
})();

(node as any).hash = "fca1821102bc19c44fa2575989dacce8";

export default node;
