/**
 * @generated SignedSource<<20e5ba021c6154908a79554b4d79c996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useVisibleMetrics_ReportingPlatform_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useShouldHideCurrencyMetrics_ReportingPlatformLib_company">;
  readonly " $fragmentType": "useVisibleMetrics_ReportingPlatform_company";
};
export type useVisibleMetrics_ReportingPlatform_company$key = {
  readonly " $data"?: useVisibleMetrics_ReportingPlatform_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useVisibleMetrics_ReportingPlatform_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useShouldHideCurrencyMetrics_ReportingPlatformLib_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "1903c98c7d2b4fff5bd48230335aed0e";

export default node;
