/**
 * @generated SignedSource<<b08e9413ef847dd64940055455a43e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterOperator = "FILTER_OPERATOR_EQUALS" | "FILTER_OPERATOR_IN" | "FILTER_OPERATOR_NOT_EQUALS" | "FILTER_OPERATOR_NOT_IN" | "FILTER_OPERATOR_UNKNOWN" | "%future added value";
export type MetricsOrderByDirection = "METRICS_ORDER_BY_DIRECTION_ASC" | "METRICS_ORDER_BY_DIRECTION_DESC" | "METRICS_ORDER_BY_DIRECTION_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
export type DimensionGrouping = {
  dimensionId: string;
  granularity?: TimeDimensionGranularity | null;
};
export type DimensionFilter = {
  dimensionId: string;
  endDate?: SerializedDateTime | null;
  list?: DimensionFilterValuesList | null;
  nodeId?: string | null;
  nodeIds?: ReadonlyArray<string> | null;
  operator?: FilterOperator | null;
  startDate?: SerializedDateTime | null;
  value?: string | null;
};
export type DimensionFilterValuesList = {
  values: ReadonlyArray<string>;
};
export type MetricsOrdering = {
  dimensionId?: string | null;
  direction: MetricsOrderByDirection;
  metricId?: string | null;
};
export type MetricsTableContextReportingPlatformQuery$variables = {
  after?: string | null;
  companyId: string;
  filters: ReadonlyArray<DimensionFilter>;
  first: number;
  groupings: ReadonlyArray<DimensionGrouping>;
  metricIds: ReadonlyArray<string>;
  orderBy?: ReadonlyArray<MetricsOrdering> | null;
};
export type MetricsTableContextReportingPlatformQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MetricsTableContextReportingPlatform_query">;
};
export type MetricsTableContextReportingPlatformQuery = {
  response: MetricsTableContextReportingPlatformQuery$data;
  variables: MetricsTableContextReportingPlatformQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupings"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metricIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v7 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MetricsTableContextReportingPlatformQuery",
    "selections": [
      {
        "args": [
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MetricsTableContextReportingPlatform_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "MetricsTableContextReportingPlatformQuery",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters"
                  },
                  (v8/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "groupings",
                    "variableName": "groupings"
                  },
                  {
                    "kind": "Variable",
                    "name": "metricIds",
                    "variableName": "metricIds"
                  },
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderBy"
                  }
                ],
                "concreteType": "MetricsTableConnection",
                "kind": "LinkedField",
                "name": "metricsTable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MetricValue",
                    "kind": "LinkedField",
                    "name": "aggregateValues",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MetricDefinition",
                    "kind": "LinkedField",
                    "name": "definitions",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metricId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dataType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimensionWrapper",
                        "kind": "LinkedField",
                        "name": "dimensionWrappers",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "dimension",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v12/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "granularities",
                                    "storageKey": null
                                  }
                                ],
                                "type": "TimeDimension",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v12/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "possibleValues",
                                    "storageKey": null
                                  }
                                ],
                                "type": "StringDimension",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupedMetricsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GroupedMetrics",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dimensionValues",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MetricValue",
                            "kind": "LinkedField",
                            "name": "values",
                            "plural": true,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6dd8c574f34ea649a318a60233b41335",
    "id": null,
    "metadata": {},
    "name": "MetricsTableContextReportingPlatformQuery",
    "operationKind": "query",
    "text": "query MetricsTableContextReportingPlatformQuery(\n  $companyId: ID!\n  $metricIds: [String!]!\n  $groupings: [DimensionGrouping!]!\n  $filters: [DimensionFilter!]!\n  $first: Int!\n  $after: String\n  $orderBy: [MetricsOrdering!]\n) {\n  ...MetricsTableContextReportingPlatform_query_2HEEH6\n}\n\nfragment MetricsTableContextReportingPlatform_query_2HEEH6 on Query {\n  company: node(id: $companyId) {\n    __typename\n    id\n    ... on Company {\n      metricsTable(filters: $filters, groupings: $groupings, metricIds: $metricIds, first: $first, after: $after, orderBy: $orderBy) {\n        totalCount\n        pageInfo {\n          ...usePaginatedDataFragment_pageInfo\n        }\n        aggregateValues {\n          value\n        }\n        definitions {\n          name\n          metricId\n          dataType\n          dimensionWrappers {\n            dimension {\n              __typename\n              ... on TimeDimension {\n                dimensionId\n                name\n                granularities\n              }\n              ... on StringDimension {\n                dimensionId\n                name\n                possibleValues\n              }\n            }\n          }\n        }\n        edges {\n          node {\n            dimensionValues\n            values {\n              value\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n"
  }
};
})();

(node as any).hash = "d274e41d8d76ad14799080cb12bd82e0";

export default node;
