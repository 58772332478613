/**
 * @generated SignedSource<<4eca3f084483e347c0b7725003e30df2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSegmentReportUrlDeepLink_segment$data = {
  readonly company: {
    readonly externalId: number;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "useSegmentReportUrlDeepLink_segment";
};
export type useSegmentReportUrlDeepLink_segment$key = {
  readonly " $data"?: useSegmentReportUrlDeepLink_segment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSegmentReportUrlDeepLink_segment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSegmentReportUrlDeepLink_segment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Segment",
  "abstractKey": null
};

(node as any).hash = "985c31c5cd8297612c86ec6a87c8afef";

export default node;
