/**
 * @generated SignedSource<<a965ac0fd66f084c5a67003def2e794c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateRawMetricsRows_ReportingPlatform_report$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useVisibleMetrics_ReportingPlatform_report">;
  readonly " $fragmentType": "useGenerateRawMetricsRows_ReportingPlatform_report";
};
export type useGenerateRawMetricsRows_ReportingPlatform_report$key = {
  readonly " $data"?: useGenerateRawMetricsRows_ReportingPlatform_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_report">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateRawMetricsRows_ReportingPlatform_report",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_report"
    }
  ],
  "type": "Report",
  "abstractKey": null
};

(node as any).hash = "da60d158fe4fda7b536f91b8d6b04451";

export default node;
