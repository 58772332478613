import _useAiProReportingStates_ReportingPlatform_query from "./__generated__/useAiProReportingStates_ReportingPlatform_query.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import useAiProReportingStates_ReportingPlatform_query from './__generated__/useAiProReportingStates_ReportingPlatform_query.graphql';
_useAiProReportingStates_ReportingPlatform_query;
export const useAiProReportingStates = queryRef => {
  var _fragmentData$aiProCh, _fragmentData$aiProCh2;

  const fragmentData = useFragment(useAiProReportingStates_ReportingPlatform_query, queryRef); // Temporary solution until BIR-1492 is ready to be implemented
  // If any user has ever had AI Pro enabled, they would have a flag override entry in the database

  const hasAiProEverBeenEnabled = !!(fragmentData !== null && fragmentData !== void 0 && (_fragmentData$aiProCh = fragmentData.aiProChurnState) !== null && _fragmentData$aiProCh !== void 0 && (_fragmentData$aiProCh2 = _fragmentData$aiProCh.edges) !== null && _fragmentData$aiProCh2 !== void 0 && _fragmentData$aiProCh2.length);
  const isAiProEnabled = useCompanyFeatureFlag('ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE');
  const isAiProReportingDisabled = useCompanyFeatureFlag('DISABLE_REPORTING_AI_PRO_BUNDLE');
  const isAiProReportingEnabled = useCompanyFeatureFlag('ENABLE_REPORTING_AI_PRO_BUNDLE');
  const isIdentityDisabled = useCompanyFeatureFlag('DISABLE_DEVICE_BASED_EVENT_MATCHING');
  const isAiProAggregateReportingEnabled = useCompanyFeatureFlag('ENABLE_AI_PRO_AGGREGATE_REPORTING');
  const showAiProReportingFeatures = !isAiProReportingDisabled && (isAiProEnabled || isAiProReportingEnabled || hasAiProEverBeenEnabled);
  const showAiProChurnState = !isAiProEnabled && hasAiProEverBeenEnabled;
  const showAiProDashboard = showAiProReportingFeatures && isAiProAggregateReportingEnabled;
  return {
    isIdentityDisabled,
    showAiProReportingFeatures,
    showAiProDashboard,
    showAiProChurnState
  };
};
export const AI_METRICS_TO_HIDE = ['campaign_suppressed_sends', 'identity_ai_total_sends', 'identity_ai_total_clicks', 'identity_ai_click_rate', 'identity_ai_total_unsubscribes', 'identity_ai_unsubscribe_rate', 'identity_ai_conversions', 'identity_ai_revenue', 'identity_ai_conversion_rate', 'identity_ai_revenue_per_send', 'identity_ai_average_order_value'];
export const AI_DIMENSIONS_TO_HIDE = ['audience_ai_segment_group', 'has_audience_ai', 'identity_group'];
export const AUDIENCES_AI_OPTIMIZED_DIMENSION_ID = 'has_audience_ai';