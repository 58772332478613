/**
 * @generated SignedSource<<79a6579b947aad99089f4ddd30f66781>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MetricsTableContextReportingPlatform_query$data = {
  readonly company: {
    readonly id: string;
    readonly metricsTable?: {
      readonly aggregateValues: ReadonlyArray<{
        readonly value: number | null;
      }>;
      readonly definitions: ReadonlyArray<{
        readonly dataType: MetricDataType;
        readonly dimensionWrappers: ReadonlyArray<{
          readonly dimension: {
            readonly __typename: "StringDimension";
            readonly dimensionId: string;
            readonly name: string;
            readonly possibleValues: ReadonlyArray<string>;
          } | {
            readonly __typename: "TimeDimension";
            readonly dimensionId: string;
            readonly granularities: ReadonlyArray<TimeDimensionGranularity>;
            readonly name: string;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          };
        }>;
        readonly metricId: string;
        readonly name: string;
      }>;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly dimensionValues: ReadonlyArray<string>;
          readonly values: ReadonlyArray<{
            readonly value: number | null;
          }>;
        };
      }>;
      readonly pageInfo: {
        readonly " $fragmentSpreads": FragmentRefs<"usePaginatedDataFragment_pageInfo">;
      };
      readonly totalCount: number;
    } | null;
  } | null;
  readonly " $fragmentType": "MetricsTableContextReportingPlatform_query";
};
export type MetricsTableContextReportingPlatform_query$key = {
  readonly " $data"?: MetricsTableContextReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsTableContextReportingPlatform_query">;
};

import MetricsTableContextReportingPlatformCompanyRefetchFragment_graphql from './MetricsTableContextReportingPlatformCompanyRefetchFragment.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "groupings"
    },
    {
      "kind": "RootArgument",
      "name": "metricIds"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": MetricsTableContextReportingPlatformCompanyRefetchFragment_graphql
    }
  },
  "name": "MetricsTableContextReportingPlatform_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "after",
                  "variableName": "after"
                },
                {
                  "kind": "Variable",
                  "name": "filters",
                  "variableName": "filters"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                },
                {
                  "kind": "Variable",
                  "name": "groupings",
                  "variableName": "groupings"
                },
                {
                  "kind": "Variable",
                  "name": "metricIds",
                  "variableName": "metricIds"
                },
                {
                  "kind": "Variable",
                  "name": "orderBy",
                  "variableName": "orderBy"
                }
              ],
              "concreteType": "MetricsTableConnection",
              "kind": "LinkedField",
              "name": "metricsTable",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "usePaginatedDataFragment_pageInfo"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MetricValue",
                  "kind": "LinkedField",
                  "name": "aggregateValues",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MetricDefinition",
                  "kind": "LinkedField",
                  "name": "definitions",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "metricId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dataType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DimensionWrapper",
                      "kind": "LinkedField",
                      "name": "dimensionWrappers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "dimension",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "granularities",
                                  "storageKey": null
                                }
                              ],
                              "type": "TimeDimension",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "possibleValues",
                                  "storageKey": null
                                }
                              ],
                              "type": "StringDimension",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GroupedMetricsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GroupedMetrics",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dimensionValues",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MetricValue",
                          "kind": "LinkedField",
                          "name": "values",
                          "plural": true,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2d9326d868ae5c3b868232295b361282";

export default node;
