/**
 * @generated SignedSource<<04a9849ca22217827d343c1cb45f80e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportTable_ReportingPlatformLib_metricsTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metricsTable" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable" | "useVisibleMetrics_ReportingPlatform_metricsTable">;
  readonly " $fragmentType": "ReportTable_ReportingPlatformLib_metricsTable";
};
export type ReportTable_ReportingPlatformLib_metricsTable$key = {
  readonly " $data"?: ReportTable_ReportingPlatformLib_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportTable_ReportingPlatformLib_metricsTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportTable_ReportingPlatformLib_metricsTable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_metricsTable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_metricsTable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_metricsTable"
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};

(node as any).hash = "5943509dc646f276183aa77a195ae2e2";

export default node;
