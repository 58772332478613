/**
 * @generated SignedSource<<f3fd9ed8b96671321a2798f90fb930f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useToolbarRegionFilter_ReportingPlatformLib_company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useMultiRegionMode_AnalyticsUI_company">;
  readonly " $fragmentType": "useToolbarRegionFilter_ReportingPlatformLib_company";
};
export type useToolbarRegionFilter_ReportingPlatformLib_company$key = {
  readonly " $data"?: useToolbarRegionFilter_ReportingPlatformLib_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useToolbarRegionFilter_ReportingPlatformLib_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useToolbarRegionFilter_ReportingPlatformLib_company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMultiRegionMode_AnalyticsUI_company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "d3b5bcb318417fa2e85c5cf35033a97e";

export default node;
