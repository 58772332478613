import { faker } from '@faker-js/faker';
import mergeWith from 'lodash/mergeWith';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';

import { castDateAsSerializedDateTime, castStringAsSerializedDateTime } from '@attentive/data';
import {
  DateFilterPeriod,
  DimensionWrapper,
  Metric,
  MetricAggregationType,
  MetricConnotation,
  MetricDataType,
  MetricDefinition,
  ReportClass,
  ReportDeliveryFrequency,
  ReportDomain,
  ReportType,
} from '@attentive/data/types';
import { createConnection, createNode, GraphQLNode, MockConnection } from '@attentive/mock-data';

import { MetricFactory, TimeDimensionGranularity, DimensionFilter } from '../metrics';

import { Report, ReportFilterWrapper, ReportGroupingWrapper } from './types';

const mockUser1 = {
  email: 'firstname.lastname.1@attentive.com',
  firstName: 'firstName1',
  lastName: 'lastName1',
  id: 1,
};
const mockUser2 = {
  email: 'firstname.lastname.2@attentive.com',
  firstName: 'firstName2',
  lastName: 'lastName2',
  id: 2,
};
const mockUser3 = {
  email: 'firstname.lastname.3@attentive.com',
  firstName: 'firstName3',
  lastName: 'lastName3',
  id: 3,
};
const mockUser4 = {
  email: 'firstname.lastname.4@attentive.com',
  firstName: 'firstName4',
  lastName: 'lastName4',
  id: 4,
};
export const mockAuthUser = {
  email: 'reportingAuthUser@attentive.com',
  firstName: 'firstName5',
  lastName: 'lastName5',
  id: 5,
};

export const MockAccountFactory = {
  createAccount: ({
    email,
    id,
    firstName,
    lastName,
  }: {
    email: string;
    id: number;
    firstName?: string;
    lastName?: string;
  }) => {
    return {
      created: castStringAsSerializedDateTime('2023-01-01'),
      email,
      firstName: firstName || '',
      id: `${id}`,
      internalId: id,
      lastName: lastName || '',
      phone: '',
      shouldUseSso: false,
    };
  },
  createReportRecipient: ({
    email,
    id,
    firstName,
    lastName,
  }: {
    email: string;
    id: number;
    firstName: string;
    lastName: string;
  }) => {
    return { account: MockAccountFactory.createAccount({ email, id, firstName, lastName }) };
  },
  createDefaultReportRecipients: () => {
    return [
      MockAccountFactory.createReportRecipient(mockUser2),
      MockAccountFactory.createReportRecipient(mockUser3),
      MockAccountFactory.createReportRecipient(mockUser4),
    ];
  },
  createDefaultPossibleRecipientList: () => {
    return [
      MockAccountFactory.createReportRecipient(mockUser1),
      MockAccountFactory.createReportRecipient(mockUser2),
      MockAccountFactory.createReportRecipient(mockUser3),
      MockAccountFactory.createReportRecipient(mockUser4),
      MockAccountFactory.createReportRecipient(mockAuthUser),
    ];
  },
};

const NUM_DOMAINS = 7;
const MOCK_DOMAINS: ReportDomain[] = [...Array(NUM_DOMAINS)].map((_, index) => {
  const name = `Domain ${index} ${faker.commerce.productName()}`;
  return {
    // hack until the schema changes are in place -- remove when reportdomain id is gone
    id: name,
    name,
    description: faker.lorem.paragraph(),
    displayOrder: NUM_DOMAINS - index,
  };
  // hack until the schema changes are in place -- remove when reportdomain id is gone
}) as unknown as ReportDomain[];

export type DeepPartial<T> = T extends {}
  ? {
      [Key in keyof T]?: DeepPartial<T[Key]>;
    }
  : T;
export type ReportOverride = DeepPartial<Report> & {
  metricIds?: Report['metricIds'];
};

interface CreateReportListArgs {
  numReports: number;
  overridesList?: ReportOverride[];
}

const DEFAULT_METRICS: Metric[] = [
  {
    definition: {
      metricId: 'metricA',
      name: 'Metric A',
      aggregationType: MetricAggregationType.MetricAggregationTypeAverage,
      connotation: MetricConnotation.MetricConnotationNegative,
      description: '',
      dataType: MetricDataType.MetricDataTypeCurrency,
      domain: '',
      dimensionWrappers: [],
      dimensions: [],
    },
    aggregateValue: 4,
    errors: [],
    groupedValues: [],
  },
  {
    definition: {
      metricId: 'metricB',
      name: 'Metric B',
      aggregationType: MetricAggregationType.MetricAggregationTypeAverage,
      connotation: MetricConnotation.MetricConnotationNegative,
      description: '',
      dataType: MetricDataType.MetricDataTypeCurrency,
      domain: '',
      dimensionWrappers: [],
      dimensions: [],
    },
    aggregateValue: 4,
    errors: [],
    groupedValues: [],
  },
  {
    definition: {
      metricId: 'metricC',
      name: 'Metric C',
      aggregationType: MetricAggregationType.MetricAggregationTypeAverage,
      connotation: MetricConnotation.MetricConnotationNegative,
      description: '',
      dataType: MetricDataType.MetricDataTypeCurrency,
      domain: '',
      dimensionWrappers: [],
      dimensions: [],
    },
    aggregateValue: 4,
    errors: [],
    groupedValues: [],
  },
];

const DEFAULT_DIMENSION_WRAPPERS: DimensionWrapper[] = [
  {
    dimension: {
      __typename: 'TimeDimension',
      description: 'Date description',
      dimensionId: 'date',
      name: 'Date',
      earliestAvailableDate: castDateAsSerializedDateTime(new Date()),
      granularities: [TimeDimensionGranularity.TimeDimensionGranularityDaily],
    },
  },
  {
    dimension: {
      __typename: 'StringDimension',
      description: 'Dimension A description',
      dimensionId: 'dimension_a',
      name: 'Dimension A',
      possibleValues: [],
    },
  },
  {
    dimension: {
      __typename: 'StringDimension',
      description: 'Dimension B description',
      dimensionId: 'dimension_b',
      name: 'Dimension B',
      possibleValues: [],
    },
  },
  {
    dimension: {
      __typename: 'StringDimension',
      description: 'Dimension C description',
      dimensionId: 'dimension_c',
      name: 'Dimension C',
      possibleValues: [],
    },
  },
  {
    dimension: {
      __typename: 'StringDimension',
      description: 'Dimension D description',
      dimensionId: 'dimension_d',
      name: 'Dimension D',
      possibleValues: [],
    },
  },
];

const DEFAULT_DIMENSION_GROUPINGS: ReportGroupingWrapper[] = [
  {
    grouping: {
      __typename: 'ReportTimeGrouping',
      dimensionId: 'date',
      granularity: TimeDimensionGranularity.TimeDimensionGranularityWeekly,
    },
  },
];

const DEFAULT_DIMENSION_FILTERS: ReportFilterWrapper[] = [
  {
    filter: {
      __typename: 'ReportTimeFilter',
      dimensionId: 'date',
      dateFilterPeriod: DateFilterPeriod.DateFilterPeriodLast_30Days,
      endDate: null,
      startDate: null,
    },
  } as unknown as ReportFilterWrapper,
];

export type ReportFactoryMode =
  | 'STANDARD'
  | 'CUSTOM'
  | 'CUSTOM_NO_DELIVERY_FREQUENCIES'
  | 'NO_WRITE_ACCESS';

const getReportModeOverrides = (mode: ReportFactoryMode): Partial<Report> => {
  switch (mode) {
    case 'STANDARD':
      return {
        reportType: ReportType.ReportTypeStandard,
        deliveryFrequencies: [],
        hasWriteAccess: null,
      };

    case 'CUSTOM':
      return {
        reportType: ReportType.ReportTypeCustom,
        deliveryFrequencies: [ReportDeliveryFrequency.ReportDeliveryFrequencyMonthly],
        hasWriteAccess: true,
      };
    case 'NO_WRITE_ACCESS':
      return {
        reportType: ReportType.ReportTypeCustom,
        deliveryFrequencies: [ReportDeliveryFrequency.ReportDeliveryFrequencyMonthly],
        hasWriteAccess: false,
      };

    case 'CUSTOM_NO_DELIVERY_FREQUENCIES':
      return {
        reportType: ReportType.ReportTypeCustom,
        deliveryFrequencies: [],
        hasWriteAccess: true,
      };

    default:
      return {};
  }
};

export interface CreateReportArgs {
  numMetrics?: number;
  groupingDimensionIds?: string[];
  filterDimensionIds?: string[];
  mode?: ReportFactoryMode;
  overrides?: ReportOverride;
  reportClassOverrides?: Partial<ReportClass>;
  filters?: DimensionFilter[];
}

export const MockReportFactory = {
  create: ({
    numMetrics = 5,
    groupingDimensionIds,
    filterDimensionIds,
    filters,
    mode = 'CUSTOM',
    overrides,
    reportClassOverrides,
  }: CreateReportArgs = {}): GraphQLNode & Report => {
    const name = `Report  ${faker.random.word()} ${faker.random.word()} ${faker.datatype.number()}`;

    const generatedGroupingDimensions =
      // if a list of grouping dimensions ids are provided, use those to create the grouping object
      (groupingDimensionIds !== undefined &&
        MockReportFactory.createReportGroupingWrappers(groupingDimensionIds)) ||
      // set to random default because the consumer doesn't care what the values are
      DEFAULT_DIMENSION_GROUPINGS;

    const reportClass = MockReportFactory.createReportClass(
      generatedGroupingDimensions,
      filterDimensionIds || groupingDimensionIds,
      overrides?.metricIds,
      reportClassOverrides
    );

    const metrics = MetricFactory.createMetricListForReport({
      numMetrics,
      groupingDimensions: generatedGroupingDimensions,
      filters,
    });

    const defaultReportDomain = faker.helpers.arrayElement(MOCK_DOMAINS);

    const baseReport = {
      __typename: 'Report',
      id: snakeCase(name),
      reportId: snakeCase(name),
      reportDomain: defaultReportDomain,
      name,
      metricIds: metrics.map((m) => m.definition.metricId),
      filterDimensions: DEFAULT_DIMENSION_FILTERS,
      metrics,
      groupingDimensions: generatedGroupingDimensions,
      supportsGroupByEntireRange: true,
      reportClass,
      createdAt: castDateAsSerializedDateTime(new Date()),
      createdBy: MockAccountFactory.createAccount(mockUser1),
      updated: castStringAsSerializedDateTime('2022-07-28'),
      updatedBy: MockAccountFactory.createAccount(mockUser3),
      recipients: MockAccountFactory.createDefaultReportRecipients(),
      nextDeliveryDate: castDateAsSerializedDateTime(new Date()),
      summaryMetricIds: [],
      summaryMetrics: null,
      selectedChart: null,
      selectedMetricId: null,
    };

    const modeOverrides = getReportModeOverrides(mode);

    // mergWith so that we can override with empty arrays
    const report = mergeWith(baseReport, modeOverrides, overrides, (obj, src) => {
      if (src !== undefined) {
        return src;
      }
      return obj;
    }) as unknown as Report;

    return createNode('Report', report);
  },

  createList: (
    { numReports, overridesList }: CreateReportListArgs = { numReports: 5 }
  ): Report[] => {
    return [...Array(numReports)].map((_, index) =>
      MockReportFactory.create({
        overrides: overridesList ? overridesList[index] : undefined,
      })
    );
  },
  createReportConnection: ({
    numReports,
    page = 0,
    limit,
    reports,
  }: {
    numReports: number;
    page?: number;
    limit?: number;
    reports?: Report[];
  }): MockConnection<'Report', Report> => {
    return createConnection('Report', reports || MockReportFactory.createList({ numReports }), {
      includeTotalCount: true,
      limit: limit || numReports,
      page,
    });
  },
  createReportGroupingWrappers: (dimensionIds: string[]): ReportGroupingWrapper[] => {
    const reportGroupingWrapper: Array<Partial<ReportGroupingWrapper>> = dimensionIds.map(
      (dimensionId) => {
        return {
          grouping: {
            __typename: dimensionId === 'date' ? 'ReportTimeGrouping' : 'ReportStringGrouping',
            dimensionId,
            granularity:
              dimensionId === 'date'
                ? TimeDimensionGranularity.TimeDimensionGranularityWeekly
                : null,
          },
        } as ReportGroupingWrapper;
      }
    );
    return reportGroupingWrapper as unknown as ReportGroupingWrapper[];
  },
  createReportClass: (
    groupingWrappers: ReportGroupingWrapper[],
    filterDimensionIds?: string[],
    overrideMetricIds?: string[],
    overrides?: Partial<ReportClass>
  ): ReportClass => {
    const necessaryDimensions: DimensionWrapper[] = groupingWrappers.reduce(
      (curArray, { grouping }) => {
        if (grouping.__typename === 'ReportStringGrouping') {
          const name = startCase(grouping.dimensionId.replace('_', ' '));

          curArray.push({
            dimension: {
              __typename: 'StringDimension',
              description: `${name} description`,
              dimensionId: grouping.dimensionId,
              name,
              possibleValues: [],
            },
          });
        }
        return curArray;
      },
      [] as DimensionWrapper[]
    );

    const filters: DimensionWrapper[] = filterDimensionIds
      ? filterDimensionIds.map((f) => ({
          dimension: {
            __typename: 'StringDimension',
            description: `${f} description`,
            dimensionId: f,
            name: f,
            possibleValues: [],
          },
        }))
      : DEFAULT_DIMENSION_WRAPPERS;

    const metrics: Metric[] = [
      ...(overrideMetricIds || []).map((metricId) => MetricFactory.createMetric({ metricId })),
      ...DEFAULT_METRICS,
    ];
    const metricDefinitions: MetricDefinition[] = metrics.map((m) => m.definition);
    return {
      __typename: 'ReportClass',
      id: 'some report class',
      name: 'some report class name',
      groupingDimensions: [
        {
          dimension: {
            __typename: 'TimeDimension',
            description: 'Date description',
            dimensionId: 'date',
            name: 'Date',
            earliestAvailableDate: castDateAsSerializedDateTime(new Date()),
            granularities: [TimeDimensionGranularity.TimeDimensionGranularityDaily],
          },
        },
        ...necessaryDimensions,
        ...DEFAULT_DIMENSION_WRAPPERS,
      ],
      filterDimensions: [
        {
          dimension: {
            __typename: 'TimeDimension',
            description: 'Date description',
            dimensionId: 'date',
            name: 'Date',
            earliestAvailableDate: castDateAsSerializedDateTime(new Date()),
            granularities: [TimeDimensionGranularity.TimeDimensionGranularityDaily],
          },
        },
        ...filters,
      ],
      metricDefinitions,
      metrics,
      ...(!!overrides && overrides),
    };
  },
};
