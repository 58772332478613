/**
 * @generated SignedSource<<14d710fe022abc614eebe906cba1afd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGenerateRawMetricsRows_ReportingPlatform_metricsTable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useReportMetricsData_AnalyticsUI_metricsTable" | "useVisibleMetrics_ReportingPlatform_metricsTable">;
  readonly " $fragmentType": "useGenerateRawMetricsRows_ReportingPlatform_metricsTable";
};
export type useGenerateRawMetricsRows_ReportingPlatform_metricsTable$key = {
  readonly " $data"?: useGenerateRawMetricsRows_ReportingPlatform_metricsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_metricsTable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGenerateRawMetricsRows_ReportingPlatform_metricsTable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVisibleMetrics_ReportingPlatform_metricsTable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useReportMetricsData_AnalyticsUI_metricsTable"
    }
  ],
  "type": "MetricsTableConnection",
  "abstractKey": null
};

(node as any).hash = "ad046cc98ebf7afb0908b913bf87b3df";

export default node;
