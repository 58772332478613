import _useReportDomains_AnalyticsUI_query from "./__generated__/useReportDomains_AnalyticsUI_query.graphql";
import _useReportDomains_AnalyticsUI_company from "./__generated__/useReportDomains_AnalyticsUI_company.graphql";
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { AI_JOURNEY_PERFORMANCE_REPORT_ID, AI_PRO_DOMAIN_ID, CAMPAIGNS_DOMAIN_ID, IDENTITY_AI_REPORT_CLASS, SEGMENTED_CAMPAIGNS_DOMAIN_ID } from '../constants';
import { useAiProReportingStates, useHasAIJourneysPerformanceReportAccess } from '.';
import useReportDomains_AnalyticsUI_company from './__generated__/useReportDomains_AnalyticsUI_company.graphql';
import useReportDomains_AnalyticsUI_query from './__generated__/useReportDomains_AnalyticsUI_query.graphql';
_useReportDomains_AnalyticsUI_company;
_useReportDomains_AnalyticsUI_query;
export const useReportDomains = _ref => {
  var _company$reports, _reportsToRender;

  let {
    companyRef,
    queryRef
  } = _ref;
  const company = useFragment(useReportDomains_AnalyticsUI_company, companyRef);
  const query = useFragment(useReportDomains_AnalyticsUI_query, queryRef);
  const {
    isIdentityDisabled,
    showAiProReportingFeatures,
    showAiProChurnState
  } = useAiProReportingStates(query);
  const {
    hasAIJourneysPerformanceReportAccess
  } = useHasAIJourneysPerformanceReportAccess(query);
  const areSegmentedReportsEnabled = useCompanyFeatureFlag('ENABLE_REPORTS_LIBRARY_SEGMENTED_REPORTS');
  const allReports = [...((company === null || company === void 0 ? void 0 : (_company$reports = company.reports) === null || _company$reports === void 0 ? void 0 : _company$reports.edges) || [])];
  let reportsToRender = allReports; // if AIPro or identity is disabled, hide identity ai reports

  if (!showAiProReportingFeatures || isIdentityDisabled) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node$reportCl;

      return ((_report$node$reportCl = report.node.reportClass) === null || _report$node$reportCl === void 0 ? void 0 : _report$node$reportCl.name) !== IDENTITY_AI_REPORT_CLASS;
    });
  } // if AIPro is disabled, hide all ai reports


  if (!showAiProReportingFeatures) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node;

      return (report === null || report === void 0 ? void 0 : (_report$node = report.node) === null || _report$node === void 0 ? void 0 : _report$node.reportDomain.domainId) !== AI_PRO_DOMAIN_ID;
    });
  } // if we don't have AI journey access, hide AI journey performance report


  if (!hasAIJourneysPerformanceReportAccess) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node2;

      return (report === null || report === void 0 ? void 0 : (_report$node2 = report.node) === null || _report$node2 === void 0 ? void 0 : _report$node2.reportId) !== AI_JOURNEY_PERFORMANCE_REPORT_ID;
    });
  } // show either the segmented campaign reports or the non-segmented campaign reports


  if (areSegmentedReportsEnabled) {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node3;

      return (report === null || report === void 0 ? void 0 : (_report$node3 = report.node) === null || _report$node3 === void 0 ? void 0 : _report$node3.reportDomain.domainId) !== CAMPAIGNS_DOMAIN_ID;
    });
  } else {
    reportsToRender = reportsToRender.filter(report => {
      var _report$node4;

      return (report === null || report === void 0 ? void 0 : (_report$node4 = report.node) === null || _report$node4 === void 0 ? void 0 : _report$node4.reportDomain.domainId) !== SEGMENTED_CAMPAIGNS_DOMAIN_ID;
    });
  } // group reports by domain name


  const reportsByDomains = ((_reportsToRender = reportsToRender) === null || _reportsToRender === void 0 ? void 0 : _reportsToRender.reduce((prev, _ref2) => {
    let {
      node: report
    } = _ref2;

    // if domain hasn't been encountered yet, initialize to empty array
    if (!Object.keys(prev).includes(report.reportDomain.name)) {
      prev[report.reportDomain.name] = {
        description: report.reportDomain.description,
        title: report.reportDomain.name,
        reports: [],
        displayOrder: report.reportDomain.displayOrder,
        domainId: report.reportDomain.domainId
      };
    }

    prev[report.reportDomain.name].reports.push(report);
    return prev;
  }, {})) || {};
  const domains = Object.values(reportsByDomains);
  const sortedDomains = domains.sort((a, b) => {
    // Override displayOrder to show AI Pro domain card last if AI Pro churn state is enabled
    if (showAiProChurnState) {
      const aIsAIPro = a.domainId === AI_PRO_DOMAIN_ID;
      const bIsAIPro = b.domainId === AI_PRO_DOMAIN_ID;
      if (aIsAIPro && !bIsAIPro) return 1;
      if (!aIsAIPro && bIsAIPro) return -1;
    } // Default sorting by displayOrder


    return a.displayOrder - b.displayOrder;
  });
  return sortedDomains;
};