/**
 * @generated SignedSource<<8774ae70dcc02d91b0da572d1bd37c5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportExportButton_ReportingPlatform_report$data = {
  readonly id: string;
  readonly name: string;
  readonly reportClass: {
    readonly name: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useGenerateRawMetricsRows_ReportingPlatform_report" | "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report">;
  readonly " $fragmentType": "ReportExportButton_ReportingPlatform_report";
};
export type ReportExportButton_ReportingPlatform_report$key = {
  readonly " $data"?: ReportExportButton_ReportingPlatform_report$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportExportButton_ReportingPlatform_report">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportExportButton_ReportingPlatform_report",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportClass",
      "kind": "LinkedField",
      "name": "reportClass",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateReportMetricsColumnHeaders_ReportingPlatform_report"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGenerateRawMetricsRows_ReportingPlatform_report"
    }
  ],
  "type": "Report",
  "abstractKey": null
};
})();

(node as any).hash = "e76dcd36d61adcbfbc0398de0c99daae";

export default node;
