import _useReportMetricsData_AnalyticsUI_metricsTable from "./__generated__/useReportMetricsData_AnalyticsUI_metricsTable.graphql";
import { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { graphql } from '@attentive/data';
import { transformMetricsTable } from '../utils';
import MetricsTableFragment from './__generated__/useReportMetricsData_AnalyticsUI_metricsTable.graphql';
_useReportMetricsData_AnalyticsUI_metricsTable;
export const useReportMetricsData = metricsTableRef => {
  const metricsTableData = useFragment(MetricsTableFragment, metricsTableRef);
  const transformedMetricsTableData = useMemo(() => transformMetricsTable(metricsTableData), [metricsTableData]);
  return transformedMetricsTableData;
};