/**
 * @generated SignedSource<<5fb18faab4b28e7ba6294f43d018f58b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricDataType = "METRIC_DATA_TYPE_CURRENCY" | "METRIC_DATA_TYPE_NUMBER" | "METRIC_DATA_TYPE_PERCENT" | "METRIC_DATA_TYPE_UNKNOWN" | "%future added value";
export type TimeDimensionGranularity = "TIME_DIMENSION_GRANULARITY_15MIN" | "TIME_DIMENSION_GRANULARITY_DAILY" | "TIME_DIMENSION_GRANULARITY_HOURLY" | "TIME_DIMENSION_GRANULARITY_MONTHLY" | "TIME_DIMENSION_GRANULARITY_UNKNOWN" | "TIME_DIMENSION_GRANULARITY_WEEKLY" | "TIME_DIMENSION_GRANULARITY_YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MetricsContextReportingPlatform_query$data = {
  readonly company: {
    readonly id: string;
    readonly metrics?: ReadonlyArray<{
      readonly aggregateValue: number | null;
      readonly definition: {
        readonly dataType: MetricDataType;
        readonly dimensionWrappers: ReadonlyArray<{
          readonly dimension: {
            readonly __typename: "StringDimension";
            readonly dimensionId: string;
            readonly name: string;
            readonly possibleValues: ReadonlyArray<string>;
          } | {
            readonly __typename: "TimeDimension";
            readonly dimensionId: string;
            readonly granularities: ReadonlyArray<TimeDimensionGranularity>;
            readonly name: string;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          };
        }>;
        readonly metricId: string;
        readonly name: string;
      };
      readonly groupedValues: ReadonlyArray<{
        readonly groupingDimensionValues: ReadonlyArray<string>;
        readonly groupingDimensions: ReadonlyArray<{
          readonly key: string;
          readonly value: string;
        }>;
        readonly value: number | null;
      }>;
    }> | null;
    readonly metricsForCharts?: ReadonlyArray<{
      readonly aggregateValue: number | null;
      readonly definition: {
        readonly dataType: MetricDataType;
        readonly dimensionWrappers: ReadonlyArray<{
          readonly dimension: {
            readonly __typename: "StringDimension";
            readonly dimensionId: string;
            readonly name: string;
            readonly possibleValues: ReadonlyArray<string>;
          } | {
            readonly __typename: "TimeDimension";
            readonly dimensionId: string;
            readonly granularities: ReadonlyArray<TimeDimensionGranularity>;
            readonly name: string;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          };
        }>;
        readonly metricId: string;
        readonly name: string;
      };
      readonly groupedValues: ReadonlyArray<{
        readonly groupingDimensionValues: ReadonlyArray<string>;
        readonly groupingDimensions: ReadonlyArray<{
          readonly key: string;
          readonly value: string;
        }>;
        readonly value: number | null;
      }>;
    }> | null;
  } | null;
  readonly " $fragmentType": "MetricsContextReportingPlatform_query";
};
export type MetricsContextReportingPlatform_query$key = {
  readonly " $data"?: MetricsContextReportingPlatform_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsContextReportingPlatform_query">;
};

import MetricsContextReportingPlatformCompanyRefetchFragment_graphql from './MetricsContextReportingPlatformCompanyRefetchFragment.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v1 = {
  "kind": "Variable",
  "name": "metricIds",
  "variableName": "metricIds"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dimensionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "aggregateValue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MetricDefinition",
    "kind": "LinkedField",
    "name": "definition",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metricId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DimensionWrapper",
        "kind": "LinkedField",
        "name": "dimensionWrappers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dimension",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "granularities",
                    "storageKey": null
                  }
                ],
                "type": "TimeDimension",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "possibleValues",
                    "storageKey": null
                  }
                ],
                "type": "StringDimension",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "GroupedMetricValue",
    "kind": "LinkedField",
    "name": "groupedValues",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupingDimensionValues",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DimensionValue",
        "kind": "LinkedField",
        "name": "groupingDimensions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "chartGroupings"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "filters"
    },
    {
      "kind": "RootArgument",
      "name": "groupings"
    },
    {
      "kind": "RootArgument",
      "name": "includeMetricsForCharts"
    },
    {
      "kind": "RootArgument",
      "name": "metricIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": MetricsContextReportingPlatformCompanyRefetchFragment_graphql
    }
  },
  "name": "MetricsContextReportingPlatform_query",
  "selections": [
    {
      "alias": "company",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "groupings",
                  "variableName": "groupings"
                },
                (v1/*: any*/)
              ],
              "concreteType": "Metric",
              "kind": "LinkedField",
              "name": "metrics",
              "plural": true,
              "selections": (v5/*: any*/),
              "storageKey": null
            },
            {
              "condition": "includeMetricsForCharts",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "metricsForCharts",
                  "args": [
                    (v0/*: any*/),
                    {
                      "kind": "Variable",
                      "name": "groupings",
                      "variableName": "chartGroupings"
                    },
                    (v1/*: any*/)
                  ],
                  "concreteType": "Metric",
                  "kind": "LinkedField",
                  "name": "metrics",
                  "plural": true,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ]
            }
          ],
          "type": "Company",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8a79f12a44d2d12d29f76a9a7394c306";

export default node;
